import firebase from 'firebase';

const config = {
  apiKey: 'AIzaSyCmxcUcmStKgWnClL4ZbxUWEIPBHdtUcJM',
  authDomain: 'codex-huerto-eden.firebaseapp.com',
  projectId: 'codex-huerto-eden',
  storageBucket: 'codex-huerto-eden.appspot.com',
  messagingSenderId: '22099604804',
  appId: '1:22099604804:web:a0e3997dad058d13475311',
  measurementId: 'G-DPY5TPDJPJ',
};

firebase.initializeApp( config );

export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
export const timeStamp = firebase.firestore.Timestamp;
export type TimeStamp = firebase.firestore.Timestamp;
export const fieldValue = firebase.firestore.FieldValue;
export type FieldValue = firebase.firestore.FieldValue;
export const authPersistenceLocal = firebase.auth.Auth.Persistence.LOCAL;
export const authPersistenceSession = firebase.auth.Auth.Persistence.SESSION;
export const emailAuthProvider = firebase.auth.EmailAuthProvider;
